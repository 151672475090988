.contact-body-l, .contact-body-r {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    width: 560px;
    padding: 20px;
    text-align: justify;
}

.contact-body-r {
    text-align: center !important;
}

.head-hr {
    border: 3px solid #666666;
    opacity: 1;
    margin-top: 0;
    margin-bottom: 40px;
}

.fka-text {
    font-size: 4vmin;
    font-weight: bold;
}

.fka-address-text {
    font-size: 3vmin;
}

.form-content {
    border: 30px solid #666666;
    background-color: #666666;
    border-radius: 30px;
    margin-bottom: 30px;
}

.btn-fka-dark{
    font-weight: bolder !important;
    font-size: x-large;
    padding-top: 0.8rem !important;
    padding-bottom: 0.4rem !important;
}