@import 'bootstrap.css';

.prepend-icon {
    width: 1.5rem;
    height: 1.5rem;
}

@keyframes rotated-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
}

.app-body {
    color: #1d1b1c;
}

.fsoe {
    font-size: 3vmin;
}

.fs-75 {
    font-size: 0.75rem!important;
}

.fka-title {
    width: fit-content;
    background-color: #1c1c1c;
    color: #fff;
    padding: 0.3rem 2rem;
    font-size: 3vmin;
    text-decoration: none;
    text-transform: uppercase;
}

.fka-body {
    width: 560px;
    padding: 20px;
    text-align: justify;
}

.home-header {
    background: url("home-head-bg.png") no-repeat top right;
    background-size: auto 100%;
    padding-bottom: 30px;
    background-color: #aaaaaa;
}

.page-header {
    background: url("head-bg.png") no-repeat top right;
    background-size: auto 100%;
    background-color: #eeeeee;
}

.page-h {
    margin-top: 20vmin;
    padding-bottom: 5vmin;
}

.page-h h1 {
    color: #fff;
    font-size: 10vmin;
    font-weight: bolder;
    text-transform: uppercase;
}

.page-content {
    text-align: center;
}

.page-content-text {
    font-size: 2vmin;
    text-decoration: none;
    text-transform: uppercase;
}

.footer {
    width: 100%;
    background-color: #1d1b1c;
    color: #fff;
}

.footer-col {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    padding-top: 1rem;
    width: 330px;
    max-width: 330px;
    min-width: 330px;
}

.lnk-fka-light {
    color: #fff;
    padding: 0.1rem 0.7rem;
    font-weight: bold;
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    text-decoration: none;
    text-transform: uppercase;
}

.btn-fka-dark {
    border-radius: 50rem!important;
    background-color: #1d1b1c;
    color: #fff;
    padding: 0.3rem 2rem;
    font-weight: bold;
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 0.1rem;
}

.btn-fka-light {
    border-radius: 50rem!important;
    background-color: #fff;
    color: #1d1b1c;
    padding: 0.3rem 2rem;
    font-weight: bold;
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 0.1rem;
}

.lnk-fka-light:hover, .btn-fka-dark:hover, .btn-fka-light:hover {
    color: #aaaaaa;
}

.top-bar-end {
    width: 50px;
}

.text-a-propos, .text-expertise {
    font-size: 3vmin;
    text-decoration: none;
    text-transform: uppercase;
}

.text-expertise {
    color: #fff;
    text-align: justify;
}

.fond-expertise {
    background: url("logo-bg.png") no-repeat center center;
    background-size: 80vmax 80vmax;
    background-color: #aaaaaa;
    padding-bottom: 30px;
}
