.a-propos-body {
    display: inline-block;
}

.a-propos-details {
    text-align: justify;
    width: 45vmax;
}

.a-propos-details-img {
    margin-left: -10px;
    margin-right: 10px;
    float: left;
    width: 18vmax;
}
