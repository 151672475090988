.blog-page-head {
    width: 560px;
    padding-top: 20px;
    padding-left: 20px;
}

.item-img {
    margin-right: 10px;
    float: left;
    width: 100%;
    background-color: #aaaaaa;
}

.item-details-img {
    margin-left: -10px;
    margin-right: 10px;
    float: left;
    width: 30vmax;
}

.item-title {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    font-size: 0.95rem;
    color: #fff;
    left: 0%;
    padding: 10px;
    transform: translate(0%, -160px);
    height: 170px;
    overflow: hidden;
}

.item-summary {
    text-transform: uppercase;
    margin-top: -160px;
    color: #1c1c1c;
    font-weight: normal !important;
}

.item-summary-font {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: bold;
}

.item-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.item-date {
    line-height: 45px;
}

.item-link:hover {
    color: #fff;
    background-color: #1c1c1c;
}

.item-details {
    font-family: "Century Gothic", CenturyGothic, AppleGothic;
    width: 1120px;
    padding-left: 20px;
    padding-right: 20px;
}