.home-h {
    margin-top: 25vmin;
    margin-left: 5vmin;
}

.home-b {
    margin-top: 4vmin;
    margin-left: 5vmin;
}

.home-h h1, h2 {
    color: #fff;
    font-weight: bolder;
    text-decoration: none;
    text-transform: uppercase;
}

.home-h h1 {
    font-size: 10vmin;
    line-height: 9vmin;
}

.home-h h2 {
    font-size: 3vmin;
    line-height: 5vmin;
}

.btn-fka-cons {
    border-radius: 50rem!important;
    background-color: #1c1c1c;
    color: #fff;
    padding: 0.3rem 2rem;
    font-size: 3vmin;
    font-weight: bolder;
    text-decoration: none;
    text-transform: uppercase;
}

.btn-fka-cons:hover {
    color: #aaaaaa;
}

.block-expertise {
    width: 50vmax;
    display: inline-block;
    text-align: center;
}
